<template>
  <page-main
    title="Let's start with basic company information"
    :aside="['Core information about your work']"
    nextText="Roles you held at this company"
    buttonTabIndex="11"
    eventName="nav"
    @nav="nav('/WorkRoles/' + key)"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyName"
            label="Company or Organization Name"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyCity"
            label="City"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyState"
            label="State"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyCountry"
            label="Country"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-single-select
            v-model="industry"
            :items="baseTargetIndustries"
            :label="`What industry is ${localCompanyName} in?`"
            tabindex="5"
          >
          </r-single-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-switch
            v-model="workingCurrently"
            label="Are you currently working here?"
            tabindex="6"
          ></r-switch>
        </v-col>
        <v-col cols="12" class="r-col">
          <month-date-select
            label="Start Date"
            :value.sync="startDate"
            tabindex="7"
          ></month-date-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <month-date-select
            v-if="!workingCurrently"
            label="End Date"
            :value.sync="endDate"
            tabindex="9"
          ></month-date-select>
          <r-text-field
            v-else
            v-model="currentDisplay"
            disabled
            class="max-h-82"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as disp from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "WorkCompanyGeneral",
  data: () => ({
    currentDisplay: "Present"
  }),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }
  },
  methods: {
    save() {
      this.saveWork();
    },
    ...mapMutations("work", ["createEntry"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  },
  computed: {
    ...mapGetters("work", ["doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      display: "workGeneral.display",
      companyName: "workGeneral.companyName",
      companyCity: "workGeneral.companyCity",
      companyState: "workGeneral.companyState",
      companyCountry: "workGeneral.companyCountry",
      industry: "workGeneral.industry",
      workingCurrently: "workGeneral.workingCurrently",
      startDate: "workGeneral.startDate",
      endDate: "workGeneral.endDate"
    }),
    ...mapFields("targetIndustry", ["baseTargetIndustries"]),
    localCompanyName() {
      if (disp.IsNullorWhitespace(this.companyName)) {
        return "this company";
      } else {
        return this.companyName;
      }
    }
  }
};
</script>

<style>
/* The month-date-select control is 82 px high */
.max-h-82 {
  max-height: 82px;
}
</style>
